import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "amped",
  casinoId: 81,
  GA_TRACKING:'G-MCSGN5ERC9',

  rivalChatGroupName: "Vegas Amped",
  prettyName: "Vegas Amped",
  contactEmail: "support@casinovegasamped.com",
  docsEmail: "documents@casinovegasamped.com",

  assetsPath: "assets/brands/vegasamped",

  //SEO
  metaDescription: "Vegas Amped Casino: Play slots and win cash by online casino gaming! Spin famous slots, join live dealer games, and table games like blackjack, roulette, and poker. Top casino bonuses, VIP rewards, fast payouts, secure gaming, and easy deposits.",
  logoName: "vegas-amped-online-casino-logo.png",
  logoAltText: "Vegas Amped Casino online logo with bold orange text and card suit icons popular for high-energy slots table games and casino promotions."
};


